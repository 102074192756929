<template>
    <div class="c-filter"
        v-click-away="closeCombo"
        >
        <div class="c-filter__box"
            @click.stop="openCloseCombo()"
            v-bind:class="[openedCombo ? 'is-open': '']"
            >
            <span class="c-filter__box__placeholder">Filter</span>
            <span class="glyphicon" aria-hidden="true"
                v-bind:class="[openedCombo ? 'glyphicon-triangle-top' : 'glyphicon-triangle-bottom']"
                >
            </span>
            <ul class="c-filter__box__dropdown">
                <li class="c-filter__box__item luxury-cars "
                    @click.stop="openCloseStyleCombo()"
                    v-bind:class="[openedStyle ? 'is-open': '']"
                    >
                    <span class="c-filter__box__item-text c-filter__box__item-text--no-flex c-filter__box__item-text--margin">Style</span>
                    <span class="glyphicon" aria-hidden="true"
                        v-bind:class="[openedStyle ? 'glyphicon-menu-up' : 'glyphicon-menu-down']"
                        >
                    </span> 
                    <ul>
                        <li class="c-filter__box__item c-filter__box__item--buttons">
                            <button
                            @click.stop="selectStyle(false)"
                            v-bind:class="[!isLuxury && isLuxury != null ? 'is-active' : '']"
                            >Rent a car</button>

                            <button
                            @click.stop="selectStyle(true)"
                            v-bind:class="[isLuxury ? 'is-active' : '']"
                            >Rent a luxury car</button>
                        </li>
                    </ul>
                </li>
                <li class="c-filter__box__item"
                    @click.stop="openCloseBrandsCombo()"
                    v-bind:class="[openedBrands ? 'is-open': '']"
                    >
                    <span class="c-filter__box__item-text c-filter__box__item-text--no-flex c-filter__box__item-text--margin luxury-cars">Brands</span>
                    <span class="glyphicon glyphicon-menu-down luxury-cars" aria-hidden="true"
                        v-bind:class="[openedBrands ? 'glyphicon-menu-up' : 'glyphicon-menu-down']"
                        >
                    </span>
                    <ul>
                        <li class="c-filter__box__item"
                            v-for="item in brands"
                            @click.stop="selectOrDeselectBrand(item.id)"
                            v-bind:class="[item.id==0 ? 'hidden': '']"

                            >
                            <span class="c-filter__box__item-image">
                                <img :src="item.icon">
                            </span>
                            <span class="c-filter__box__item-text">{{ item.name }}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="c-filter__selected">
            <span>{{ filterString }}</span>

            <div class="u-mt-10">
                <span class="c-filter__selected-item"
                    v-for="item in selected"
                    >
                    {{ item.name }} <button @click.stop="selectOrDeselectBrand(item.id)">X</button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions
    } from 'vuex'
    import clickAway from '../directives/click-away'

    export default {
        directives: {
            clickAway
        },
        computed: mapGetters({
            brands: 'allBrands',
            selected: 'getSelectedBrands',
            openedCombo: 'openedCombo',
            openedStyle: 'openedStyle',
            openedBrands: 'openedBrands',
            isLuxury: 'isLuxury',
            filterString: 'getFilterString'
        }),

        methods: mapActions([
            'openCloseStyleCombo',
            'openCloseBrandsCombo',
            'selectOrDeselectBrand',
            'selectStyle',
            'openCloseCombo',
            'closeCombo'
        ]),

        created() {
            this.$store.dispatch('getAllBrands')
        }
    }
</script>
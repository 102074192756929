import $ from 'jquery'

(($) => {
    let $menu = $('.o-header__right'),
        $toggleMenu = $('.js-toggle-menu'),
        $body = $('body')
    
    $toggleMenu.on('click', function (e) {
        e.stopPropagation()
        _toggleMenu($menu, $body)

    })

    $body.on('click', function (e) {
        if ($menu.hasClass('is-active') && !$menu.is(e.target) && $menu.has(e.target).length === 0) {
            _toggleMenu($menu, $body)
        }
    })

       $('.js-login').on('click', function (e) {
            e.stopPropagation()
            $('.c-login').slideToggle();

        })

})($)

function _toggleMenu ($menu, $body) {
    $menu.toggleClass('is-active')
    $body.trigger('rw.toggle-scroll')
}
import $ from 'jquery'
const flatpickr = require("flatpickr");

(($) => {

   /* $('.timepicker').timepicker({
        timeFormat: 'HH:mm',
        startTime: '00:00',
        dynamic: false,
        dropdown: true,
        scrollbar: true
    });*/

    flatpickr(".time", {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
    });

})($)

import $ from 'jquery'

$('body').on('rw.toggle-scroll', function () {
    $(this).toggleClass('disable-scroll')
});

$('.js-drop').on('click', function(e) {
  e.preventDefault();
  $('.js-drop').removeClass('is-active');
  $('.js-drop__content').removeClass('is-active');
  $(this).addClass('is-active');
  $(this).parents('.c-faq__box').find('.js-drop__content').addClass('is-active');
});

$('.js-tab').on('click', function(e) {
  e.preventDefault();
  var tab = $(this).data('tab');
  var _this = $(this);
  $('[data-tab-content]').each(function () {
    var tabContent = $(this).data('tab-content');
    if (tab == tabContent) {
      $('.js-tab').removeClass('is-active');
      $('.js-tab-content').removeClass('is-active');
      $(this).addClass('is-active');
      $(_this).addClass('is-active');
    }
  });
});
import $ from 'jquery'

(($) => {
    let $form = $('.js-concierge-form'),
        $body = $('body')

    $('.js-toggle-concierge-popup').on('click', function(e) {
        let $parent = $(this).parent(),
            $inputs = $parent.find('input, select'),
            valid = true


        if ($inputs.length > 0 && !$form.hasClass('is-active')) {
            $inputs.each(function() {
                if ($(this).val()) {
                    $(this).css('border-color', '')
                } else {
                    valid = false
                    $(this).css('border-color', 'red')
                }
            })
        }

        if (valid) {
            $form.toggleClass('is-active')
            $('body').trigger('rw.toggle-scroll')
        }
    })

    $form.parent('form').on('submit', function(e) {
        e.preventDefault()

        let $this = $(this),
            $name = $this.find('[name="nombre"]'),
            $email = $this.find('[name="email"]'),
            $telefono = $this.find('[name="telefono"]'),
            $nacionalidad = $this.find('[name="nacionalidad"]'),
            $hotel = $this.find('[name="hotel"]'),
            $numero_habitacion = $this.find('[name="numero_habitacion"]'),
            $pasaporte = $this.find('[name="pasaporte"]'),
            $observaciones = $this.find('[name="observaciones"]'),
            $funcion = 'formularioConcierge',
            valid = true

        if (!$name.val()) {
            valid = false
            $name.css('border-color', 'red')
        } else {
            $name.css('border-color', '')
        }

        if (!$email.val()) {
            valid = false
            $email.css('border-color', 'red')
        } else {
            $email.css('border-color', '')
        }

        if (valid) {
            $.ajax({
                url: 'ajaxHandler.php',
                method: 'POST',
                data: {
                    funcion: $funcion,
                    name: $name.val(),
                    email: $email.val(),
                    telefono: $telefono.val(),
                    nacionalidad: $nacionalidad.val(),
                    hotel: $hotel.val(),
                    numero_habitacion: $numero_habitacion.val(),
                    pasaporte: $pasaporte.val(),
                    observaciones: $observaciones.val(),
                },
                success: data => {
                    if (data == "ok") {
                        $this.find('.alert-danger').hide();
                        $this.find('.alert-success').removeClass('hide').fadeIn('fast');
                        $this[0].reset();
                    } else {
                        $this.find('.alert-success').hide();
                        $this.find('.alert-danger').removeClass('hide').fadeIn('fast');
                    }
                }
            })
        }
    })
})($)
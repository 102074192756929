import Vue from 'vue'
import Vuex from 'vuex'
import filterCombo from './modules/filterCombo'
import cardList from './modules/cardList'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        filterCombo,
        cardList
    }
})
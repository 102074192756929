import api from '../../api'
import * as types from '../mutation-types'

// initial state
const state = {
    brands: [],
    openedCombo: false,
    openedStyle: true,
    openedBrands: true,
    isLuxury: null
}

// getters
const getters = {
    allBrands: state => state.brands,
    openedCombo: state => state.openedCombo,
    openedStyle: state => state.openedStyle,
    openedBrands: state => state.openedBrands,
    isLuxury: state => state.isLuxury,
    getSelectedBrands: state => state.brands.filter(brand => brand.selected),
    getFilterString: (state, getters) => {
        let filteredCount = getters.getFilteredCars.length
        const totalCount = getters.getCars.length

        if (state.isLuxury == null && getters.getSelectedBrands.length == 0) {
            filteredCount = totalCount
        }

        return `Showing ${filteredCount} of ${totalCount} results`   
    }
}

// actions
const actions = {
    getAllBrands ({ commit }) {
        api.getBrands(brands => {
            commit(types.RECEIVE_BRANDS, { brands })
        })
    },

    openCloseCombo ({ commit }) {
        commit(types.OPEN_CLOSE_COMBO, { isClickAway: false })
    },

    closeCombo ({ commit }) {
        commit(types.OPEN_CLOSE_COMBO, { isClickAway: true })
    },

    openCloseStyleCombo ({ commit, state }) {
        commit(types.OPEN_CLOSE_STYLE_COMBO)

        if (!state.openedBrands) {
            commit(types.OPEN_CLOSE_COMBO, { isClickAway: false })
        }
    },

    openCloseBrandsCombo ({ commit, state }) {
        commit(types.OPEN_CLOSE_BRANDS_COMBO)

        if (!state.openedStyle) {
            commit(types.OPEN_CLOSE_COMBO, { isClickAway: false })
        }
    },

    selectOrDeselectBrand ({ commit }, brandId) {
        commit(types.SELECT_DESELECT_BRAND, { brandId })
    },

    selectStyle({ commit, state }, boolean) {
        commit(types.SELECT_STYLE, { boolean })

        if (!state.openedBrands) {
            commit(types.OPEN_CLOSE_COMBO, { isClickAway: false })
        }
    }
}

// mutations
const mutations = {
    [types.RECEIVE_BRANDS] (state, { brands }) {
        state.brands = brands
    },

    [types.OPEN_CLOSE_COMBO] (state, { isClickAway }) {
        // When i'm closing the comboBox, the style and brands combos are being
        // opened in the background.
        if (state.openedCombo || isClickAway) {
            state.openedCombo = false
            state.openedStyle = true
            state.openedBrands = true
        } else {
            state.openedCombo = true
        }
    },

    [types.OPEN_CLOSE_STYLE_COMBO] (state) {
        state.openedStyle = !state.openedStyle
    },

    [types.OPEN_CLOSE_BRANDS_COMBO] (state) {
        state.openedBrands = !state.openedBrands
    },

    [types.SELECT_DESELECT_BRAND] (state, { brandId }) {
        const brand = state.brands.find(brand => brand.id === brandId)
        brand.selected = !brand.selected
    },

    [types.SELECT_STYLE] (state, { boolean }) {
        state.isLuxury = (state.isLuxury !== boolean) ? boolean : null
        state.openedStyle = false
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
import Vue from 'vue'
import store from './store'
import FilterCombo from './components/FilterCombo.vue'
import CardList from './components/CardList.vue'

new Vue({
    el : '#vue-root',
    store,
    components: {
        FilterCombo,
        CardList
    }
})
import $ from 'jquery'
import 'slick-carousel'

(($) => {
    $('.js-hero-slider').on('init', (e, $slick) => {
        const index = $slick.slickCurrentSlide()
        _selectNavigationOption(index)
    })

    let $slick = $('.js-hero-slider').slick({
        arrows: false,
        dots: true,
        fade: true,
        autoplay:true,
        autoplaySpeed:4000

    })

    $slick.on('beforeChange', function (e, $slick, currentIndex, nextIndex) {
        _selectNavigationOption(nextIndex)
    })

    $('.c-hero-slider__navigation ul li').on('click', function () {
        const index = $(this).index()
        $slick.slick('slickGoTo', index)
    })
})($)

function _selectNavigationOption(index) {
    $('.c-hero-slider__navigation ul').children('li').removeClass('is-active').eq(index).addClass('is-active')
}
import api from '../../api'
import * as types from '../mutation-types'

// initial state
const state = {
    cars: []
}

// getters
const getters = {
    getFilteredCars: (state, getters) => {
        let brandIds = getters.getSelectedBrands.map(brand => brand.id)
        const isLuxury = getters.isLuxury

        // If there is no brands selected, we are getting all the brand ids in
        // order to show them, otherwise will show no items in the list.
        if (brandIds.length == 0) {
            brandIds = getters.allBrands.map(brand => brand.id)
        }

        // if the "style" filter is not selected (null), i'm ignoring the
        // "isLuxury" property, otherwise im using it to filter the results.
        return state.cars.filter(car => (isLuxury == null || isLuxury === car.isLuxury) && (brandIds.indexOf(car.brand) != -1 || brandIds.length == 0))
    },

    getCars: (state) => state.cars
}

// actions
const actions = {
    getAllCars({ commit }) {
        api.getCars(cars => {
            commit(types.RECEIVE_CARS, { cars })
        })
    }
}

// mutations
const mutations = {
    [types.RECEIVE_CARS](state, { cars }) {
        state.cars = cars
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
import $ from 'jquery'

const posYTrigger = 200
let el = document.querySelector('.c-back-to-top')

window.addEventListener('scroll', () => {
    if (document.body.scrollTop > posYTrigger || document.documentElement.scrollTop > posYTrigger) {
        el.classList.add('is-active')
    } else {
        el.classList.remove('is-active')
    }
})

el.addEventListener('click', () => {
    $('html, body').animate({ scrollTop: 0 }, 500)
    return false
})

import $ from 'jquery'
import 'timepicker'
import 'bootstrap-datepicker'
import 'slick-carousel'

(($) => {
    $('.input-daterange').datepicker({
        autoclose: true
    })
    
    $('.timepicker').timepicker({
        timeFormat: 'H:i',
        disableTouchKeyboard: true

    })

    $('.js-card-slider').slick({
        slidesToShow: 2,
        infinite: false,
        arrows: true,
        dots: true,
        prevArrow: '<button class="c-card-slider__arrow c-card-slider__arrow--prev"><i class="icon-prev-arrow"></i></button>',
        nextArrow: '<button class="c-card-slider__arrow c-card-slider__arrow--next"><i class="icon-next-arrow"></i></button>',
        responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }]
    })

    $('.js-categories-slider').slick({
        slidesToShow: 3,
        infinite: false,
        arrows: true,
        dots: true,
        prevArrow: '<button class="c-card-slider__arrow c-card-slider__arrow--prev"><i class="icon-prev-arrow"></i></button>',
        nextArrow: '<button class="c-card-slider__arrow c-card-slider__arrow--next"><i class="icon-next-arrow"></i></button>',
        responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 2,
                arrows: true
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }]
    })

    $('.js-logo-slider').slick({
        slidesToShow: 4,
        infinite: true,
        arrows: false,
        dots: true,
        slidesToScroll: 4,
        autoplay:true,
        autoplaySpeed:3000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {

                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    })
})($)
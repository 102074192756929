import $ from 'jquery'

(($) => {
    $(".js-contact-submit").on('click', function() {
        var error = false;
        var name = $('input[name="name"]').val();
        var email = $('input[name="email"]').val();
        var phone = $('input[name="phone"]').val();
        var message = $('textarea[name="message"]').val();
        var acceptedPrivacy = $('input[name="acceptp"]:checked').length > 0;
        $('input[name="name"],input[name="email"],input[name="phone"],textarea[name="message"]').css('border-bottom', '1px solid #8c9192');
        $('.js-accept').css('color', '#fff');
        if (name == "") {
            $('input[name="name"]').css('border-bottom', '1px solid red');
            error = true;
        }
        if (email == "") {
            $('input[name="email"]').css('border-bottom', '1px solid red');
            error = true;
        }
        if (phone == "") {
            $('input[name="phone"]').css('border-bottom', '1px solid red');
            error = true;
        }
        if (message == "") {
            $('textarea[name="message"]').css('border-bottom', '1px solid red');
            error = true;
        }
        if (!acceptedPrivacy) {
            $('.js-accept').css('color', 'red');
            error = true;
        }
        if (error == false) {

            $.ajax({
                type: "POST",
                url: "ajaxHandler.php",
                data: {
                    funcion: "formularioContacto",
                    name: name,
                    email: email,
                    phone: phone,
                    message: message
                },
                success: function(data) {
                    if (data == "ok") {
                        $('.alert-danger').hide();
                        $('.alert-success').removeClass('hide').fadeIn('fast');
                    } else {
                        $('.alert-success').hide();
                        $('.alert-danger').removeClass('hide').fadeIn('fast');
                    }

                }
            });
        }

    })

})($)
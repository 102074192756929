import $ from 'jquery'

let markerCoordinates = {lat: 39.543132, lng: 2.594736},
centerCoordinates = {lat: 39.548132, lng: 2.624736}

window.initMap = function() {
    const styledMapType = new google.maps.StyledMapType([{"elementType":"geometry","stylers":[{"color":"#f5f5f5"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"elementType":"labels.text.stroke","stylers":[{"color":"#f5f5f5"}]},{"featureType":"administrative.land_parcel","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#dadada"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"transit.station","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#c9c9c9"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]}])

    let map = new google.maps.Map(document.querySelector('.c-map-form__map'), {
      zoom: 14,
      center: centerCoordinates
    })

    map.mapTypes.set('silver', styledMapType)
    map.setMapTypeId('silver')

    let marker = new google.maps.Marker({
      position: markerCoordinates,
      map: map,
      title: 'Mallorca Luxury Experience'
    })

    _updateMapCenter(map);
    google.maps.event.addDomListener(window, 'resize', function () {
      _updateMapCenter(map)
    })
}

function _updateMapCenter(map) {
  ($(window).width() < 992 ) ? map.setCenter(markerCoordinates) : map.setCenter(centerCoordinates)
}
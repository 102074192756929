import $ from 'jquery'

let $lightboxEl = $('[data-lightbox]')

const lightboxResize = () => {
    for (let i = 0; i < $lightboxEl.length; i++) {
        let $el = $($lightboxEl[i]),
            dataValue = $el.data('lightbox-gallery')

        if ($(window).width() < 768) {
            delete $el[0].dataset.lightbox

            $el.on('click', (e) => e.preventDefault())
        } else {
            $el[0].dataset.lightbox = dataValue
        }
    }
}

$(document).ready(lightboxResize)
$(window).resize(lightboxResize)
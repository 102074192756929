import $ from 'jquery'

(($) => {

    $("select[name='tipo_recogida']").on("change", function(){
        var tipo=$(this).val();
        changeinputs(tipo);

    });
    function changeinputs(tipo){
        $('.airport-services input').each(function(){
            if($(this).hasClass(tipo)){
                $(this).parent().show();
            }
            else{
                $(this).parent().hide();
                $(this).val('');
            }
        })
    };
    $(document).ready(function(){
        if($('input[name="tipo_trayecto"]').length>0){
            var tipo = $('input[name="tipo_trayecto"]').val();
            changeinputs(tipo);
        };
    });

    let $form = $('.js-booking-form'),
        $body = $('body')

    $('.js-toggle-booking-popup').on('click', function(e) {
        let $parent = $(this).parent(),
            $inputs = $parent.find('input, select'),
            valid = true


        if ($inputs.length > 0 && !$form.hasClass('is-active')) {
            $inputs.each(function() {
                if ($(this).parent().css('display') != 'none')
                {
                    if ($(this).val()) {
                        $(this).css('border-color', '')
                    } else {
                        valid = false
                        $(this).css('border-color', 'red')
                    }
                }
            })
        }

        if (valid) {
            $form.toggleClass('is-active')
            $('body').trigger('rw.toggle-scroll')
        }
    })

    $form.parent('form').on('submit', function(e) {
        e.preventDefault()

        let $this = $(this),
            $name = $this.find('[name="nombre"]'),
            $email = $this.find('[name="email"]'),
            $telefono = $this.find('[name="telefono"]'),
            $nacionalidad = $this.find('[name="nacionalidad"]'),
            $hotel = $this.find('[name="hotel"]'),
            $numero_habitacion = $this.find('[name="numero_habitacion"]'),
            $pasaporte = $this.find('[name="pasaporte"]'),
            $observaciones = $this.find('[name="observaciones"]'),
            $ficha = $this.find('[name="id_fichas"]'),
            $fechaDesde = $this.find('[name="fecha_desde"]'),
            $fechaHasta = $this.find('[name="fecha_hasta"]'),
            $fechaDesdeTime = $this.find('[name="fecha_desde_time"]'),
            $fechaHastaTime = $this.find('[name="fecha_hasta_time"]'),
            $personas = $this.find('[name="personas"]'),
            $identificador = $this.find('[name="identificador"]'),
            $origen = $this.find('[name="origen"]'),
            $destino = $this.find('[name="destino"]'),
            $origenVuelta = $this.find('[name="origen_vuelta"]'),
            $destinoVuelta = $this.find('[name="destino_vuelta"]'),
            $precio = $this.find('[name="precio"]'),
            $tipoRecogida = $this.find('[name="tipo_recogida"]'),
            $numeroVuelo = $this.find('[name="numero_vuelo"]'),
            $numeroVueloVuelta = $this.find('[name="numero_vuelo_vuelta"]'),
            $gerencia = $this.find('[name="gerencia"]'),
            $gerenciaval= $gerencia.prop("checked") ? 1 : 0,
            valid = true

        if (!$name.val()) {
            valid = false
            $name.css('border-color', 'red')
        } else {
            $name.css('border-color', '')
        }

        if (!$email.val()) {
            valid = false
            $email.css('border-color', 'red')
        } else {
            $email.css('border-color', '')
        }

        if (valid) {
            $.ajax({
                url: 'ajaxHandler.php',
                method: 'POST',
                data: {
                    funcion: 'formularioReserva',
                    name: $name.val(),
                    email: $email.val(),
                    telefono: $telefono.val(),
                    nacionalidad: $nacionalidad.val(),
                    hotel: $hotel.val(),
                    numero_habitacion: $numero_habitacion.val(),
                    pasaporte: $pasaporte.val(),
                    observaciones: $observaciones.val(),
                    ficha: $ficha.val(),
                    fecha_desde: $fechaDesde.val(),
                    fecha_hasta: $fechaHasta.val(),
                    fecha_desde_time: $fechaDesdeTime.val(),
                    fecha_hasta_time: $fechaHastaTime.val(),
                    personas: $personas.val(),
                    identificador: $identificador.val(),
                    origen: $origen.val(),
                    destino: $destino.val(),
                    origen_vuelta: $origenVuelta.val(),
                    destino_vuelta: $destinoVuelta.val(),
                    precio: $precio.val(),
                    tipo_recogida: $tipoRecogida.val(),
                    numero_vuelo:$numeroVuelo.val(),
                    numero_vuelo_vuelta:$numeroVueloVuelta.val(),
                    gerencia:$gerenciaval,

                },
                success: data => {
                    if (data == "ok") {
                        $this.find('.alert-danger').hide();
                        $this.find('.alert-success').removeClass('hide').fadeIn('fast');
                        $this[0].reset();
                    } else {
                        $this.find('.alert-success').hide();
                        $this.find('.alert-danger').removeClass('hide').fadeIn('fast');
                    }
                }
            })
        }
    })
})($)
import 'es6-promise/auto'
import 'jspolyfill-array.prototype.find'

import 'lightbox2'
import 'bootstrap-datepicker'
//import 'flatpickr'
import './jquery-plugin-initialization'
import './events'
import './lightbox-mobile'
import './components/time'
import './components/hero-slider'
import './components/sticky-header'
import './components/responsive-menu'
import './components/google-maps'
import './components/booking-popup'
import './components/concierge-popup'
import './components/back-to-top'
import './components/lang-selector'
import './components/contact'
import './components/reply'
import './vue/main'

import $ from 'jquery'

(($) => {
    $(document).ready(function() {

        $(".js-contact-reply").on('click', function() {
            var error = false;
            var reply = $('input[name="reply"]').val();
            var message = $('textarea[name="message"]').val();
            $('textarea[name="message"]').css('border-bottom', '1px solid #8c9192');

            if (message == "") {
                $('textarea[name="message"]').css('border-bottom', '1px solid red');
                error = true;
            }
            if (error == false) {

                $.ajax({
                    type: "POST",
                    url: "ajaxHandler.php",
                    data: {
                        funcion: "formularioReply",
                        reply: reply,
                        message: message
                    },
                    success: function(data) {
                        if (data == "ok") {
                            $('.alert-danger').hide();
                            $('.alert-success').removeClass('hide').fadeIn('fast');
                        } else {
                            $('.alert-success').hide();
                            $('.alert-danger').removeClass('hide').fadeIn('fast');
                        }

                    }
                });
            }

        })
    })

})($)
import $ from 'jquery'

(($) => {

    $(window).on('scroll', function () {
        let scrollTop = $(this).scrollTop()

        if (scrollTop > 0) {
            $('.o-header').addClass('o-header--fixed')
        } else {
            $('.o-header').removeClass('o-header--fixed')
        }
    })

    $(window).on('load', function () {
        $(this).trigger('scroll')
    })

})($)
<template>
    <div class="o-card-list">
        <div class="o-card-list__wrapper">
            <div class="o-card-list__item"
                v-for="item in cars"
                >
                <div class="c-card" :style="{'background-image': 'url('+item.image+')'}">
                    <div class="c-card__container">
                        <div class="c-card__wrapper">
                            <div class="u-flex u-flex__ai-c">
                                <div class="c-card__info">
                                    <h3>{{ item.name }}</h3>
                                    <p>{{ item.description }}</p>
                                </div>
                                <div class="c-card__btn">
                                    <a :href="item.slug" class="c-btn c-btn--w100">{{ linkText }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="o-card-list__item"
                v-for="item in brands"
                >
                <span>{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions
    } from 'vuex'

    export default {
        props: ['linkText'],
        computed: mapGetters({
            cars: 'getFilteredCars',
        }),

        created() {
            this.$store.dispatch('getAllCars')
        }
    }
</script>